<template>
  <article class="about">
    <div class="about__grid-item about__item-profile">
      <h2>About</h2>
      <img class="about__icon" src="../assets/anpan.jpg" />
      <div class="about__profile-note">@uu_ka</div>
      <div class="about__profile-note">Software Engineer</div>
      <p>Hello, world! 🏓☕🍞</p>
      <ul>
        <li>2018/11 Ame Kaze Taiyo, Inc. - Engineer</li>
        <li>2016/06 Blabo, Inc. - Lead Engineer</li>
        <li>2011/04 Rakuten, Inc. - Application Engineer</li>
        <li>
          2007/04 The University of Aizu, School of Computer Science and
          Engineering
        </li>
      </ul>
    </div>
    <div class="about__grid-item about__item-skills">
      <h2>Skills</h2>
      <ul>
        <li>Ruby *</li>
        <li>JavaScript/Sass/Vue.js *</li>
        <li>Java</li>
        <li>Android</li>
      </ul>
    </div>
    <div class="about__grid-item about__item-links">
      <h2>links</h2>
      <ul>
        <li>
          <a
            class="about__link"
            href="https://github.com/uu-ka"
            target="_blank"
          >
            github
          </a>
        </li>
        <li>
          <a
            class="about__link"
            href="https://scrapbox.io/uuka/"
            target="_blank"
          >
            scrapbox
          </a>
        </li>
      </ul>
    </div>
  </article>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "TheAbout",
});
</script>

<style scoped lang="scss">
@import "../assets/stylesheets/globals/_mediaqueries";
@import "../assets/stylesheets/globals/_variables";

.about {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 50% 1fr;
  word-break: break-all;
  padding: 50px 20px;

  @include max-screen(1000px) {
    grid-template-columns: 100%;
  }

  h2 {
    text-transform: uppercase;
    font-size: $font-size-L;
    border-bottom: 1px solid $border-color-gray;
    padding: 6px 0;
    letter-spacing: 1.5px;
    margin-bottom: 15px;
  }

  &__grid-item {
    margin-bottom: 15px;

    @include max-screen(1000px) {
      margin-bottom: 25px;
    }

    &:nth-child(1) {
      grid-row: 1 / 3;
      grid-column: 1 / 2;

      @include max-screen(1000px) {
        grid-row: auto;
        grid-column: auto;
      }
    }

    &:nth-child(2) {
      grid-row: 1 / 2;
      grid-column: 2 / 3;

      @include max-screen(1000px) {
        grid-row: auto;
        grid-column: auto;
      }
    }

    &:nth-child(3) {
      grid-row: 2/ 3;
      grid-column: 2 / 3;

      @include max-screen(1000px) {
        grid-row: auto;
        grid-column: auto;
      }
    }
  }

  &__item-profile {
    p {
      margin-bottom: 20px;
    }

    ul {
      font-size: $font-size-S;
      line-height: 1.4;
    }

    li {
      margin-bottom: 6px;
    }
  }

  &__item-skills {
    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  &__item-links {
    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  &__icon {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    margin-bottom: 10px;
  }

  &__profile-note {
    margin-bottom: 10px;
  }

  &__link {
    text-decoration: underline;
    color: $link-color;
  }
}
</style>
