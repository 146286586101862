
import { defineComponent, computed } from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faGithub, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faEnvelope, faGithub, faFacebook);

export default defineComponent({
  name: "TheHeader",
  components: {
    FontAwesomeIcon,
  },
  setup() {
    const year = computed(() => new Date().getFullYear());
    return {
      year,
    };
  },
});
