
import { defineComponent } from "vue";
import TheAbout from "./components/TheAbout.vue";
import TheHeader from "./components/TheHeader.vue";

export default defineComponent({
  name: "App",
  components: {
    TheAbout,
    TheHeader,
  },
});
